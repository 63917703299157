<template>
  <v-container fluid>
    <v-row class="align-center justify-center">
      <LookupValueBtnToggle
        code
        resource="common/division"
        v-model="filterDivision"
        mandatory
      ></LookupValueBtnToggle>
      <v-spacer></v-spacer>
      <DateInput v-model="focus" class="mx-2" />

      <v-btn outlined @click="prev()" class="mx-2">
        <v-icon left>mdi-chevron-left</v-icon> vorheriger Tag
      </v-btn>
      <v-btn outlined @click="next()" class="mx-2">
        nächster Tag<v-icon right>mdi-chevron-right</v-icon>
      </v-btn>
    </v-row>
    <v-calendar
      ref="calendar"
      v-model="focus"
      first-time="07:30"
      last-time="17:30"
      color="primary"
      type="category"
      category-show-all
      category-text="code"
      :categories="schoolClassesFiltered"
      :events="events"
      :show-interval-label="showIntervalLabel"
      interval-minutes="5"
      interval-height="5"
      :interval-style="intervalStyle"
      interval-count="129"
      v-scroll.self="onScroll"
    >
      <template v-slot:event="{ event }">
        <v-container>
          <v-row>
            <v-col
              ><strong>{{ event.name }}</strong></v-col
            >
            <v-col class="text-right">{{ event.rooms }}</v-col>
          </v-row>
          <v-row>
            {{ event.comments }}
          </v-row>
        </v-container>
      </template>
    </v-calendar>
  </v-container>
</template>
<script>
import { addDays, today } from "common/utils/date.js";

import { lessonStatusColor } from "common/utils/icons.js";

import DateInput from "common/components/DateInput.vue";
import LookupValueBtnToggle from "common/components/LookupValueBtnToggle.vue";

export default {
  name: "WeekOverviewSchoolclasses",
  components: { DateInput, LookupValueBtnToggle },
  props: ["search"],
  data() {
    return {
      categories: [],
      filterDivision: { code: 0 },
      events: [],
      focus: null,
      loading: false,
      schoolClasses: [],
      periods: [],
    };
  },
  computed: {
    schoolClassesFiltered() {
      return this.schoolClasses.filter(
        (el) => el.division.code == this.filterDivision.code
      );
    },
  },
  watch: {
    focus() {
      this.fetchEvents();
    },
    schoolClassesFiltered() {
      this.fetchEvents();
    },
  },
  methods: {
    addDays,
    lessonStatusColor,
    async fetchData() {
      this.loading = true;
      this.schoolClasses = await this.apiList({
        resource: "schoolclass/schoolclass",
      });
      this.periods = await this.apiList({
        resource: "common/period",
      });
      this.loading = false;
    },
    async fetchEvents() {
      if (!this.focus) {
        return;
      }
      this.loading = true;
      this.events = [];
      for (const schoolClass of this.schoolClassesFiltered) {
        const lessons = await this.apiList({
          resource: "register/lesson",
          query: `startDate=${this.focus}&endDate=${this.focus}&schoolclass=${schoolClass.id}`,
        });
        for (const item of lessons) {
          const eventStart = item.startTime
            ? new Date(item.date + "T" + item.startTime)
            : new Date(item.date);
          const eventEnd = item.endTime
            ? new Date(item.date + "T" + item.endTime)
            : new Date(item.date);

          this.events.push({
            name: item.course.subject.code,
            category: schoolClass.code,
            start: eventStart,
            end: eventEnd,
            timed: true,
            rooms: item.rooms.map((room) => room.code).join(","),
            comments: item.comments
              ? item.comments
              : "" + item.status.description,
            color:
              item.status.code == "asPlanned"
                ? "grey"
                : this.lessonStatusColor(item.status),
          });
        }
      }

      this.loading = false;
    },
    intervalStyle(datetime) {
      return this.periods.map((el) => el.startTime).includes(datetime.time)
        ? {}
        : { borderTopColor: "transparent" };
    },
    showIntervalLabel(datetime) {
      return this.periods.map((el) => el.startTime).includes(datetime.time);
    },

    onScroll(ev) {
      const element = ev.srcElement.getElementsByClassName(
        "v-calendar-daily__day"
      )[0];

      console.log(element.getBoundingClientRect());
    },
    prev() {
      this.focus = this.addDays(this.focus, -1);
    },
    next() {
      this.focus = this.addDays(this.focus, 1);
    },
  },
  async created() {
    await this.fetchData();
    this.focus = today();
  },
};
</script>
